import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet de color torrat presenta el marge del capell ondulat i típiques costelletes. Aquest capell té forma d’embut i fa fins a 8 cm de diàmetre. Les làmines són decurrents i de color pàl·lid. El peu és fibrós i de color semblant al capell, però un poc més clar. Les espores són blanques en massa, el·líptiques o en forma de llàgrima, de 6-8 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      